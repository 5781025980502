import React, { useState } from "react"
import PropTypes from "prop-types"

import CareersTable from "@templates/careers/components/CareersTable"
import CareersFilterRow from "@templates/careers/components/CareersFilterRow"

import "./style.scss"
import { normalizeText } from "../../../../utilities/copyUtils"

const CareersTableFilterSection = props => {
  const [departmentFilterId, setDepartmentFilterId] = useState(null)
  const [locationFilterId, setLocationFilterId] = useState(null)
  const [nameFilterString, setNameFilterString] = useState("")

  const filterCareers = React.useMemo(() => {
    return props.careers
      .filter(career => {
        // filter by nameString
        return normalizeText(career.acf.title)
          .toLowerCase()
          .match(new RegExp(normalizeText(nameFilterString)), "i")
      })
      .filter(career => {
        // filter by dept filter
        if (departmentFilterId) {
          if (!career.acf.employeeDepartment) return false
          return career.acf.employeeDepartment.id === departmentFilterId
        } else {
          return career
        }
      })
      .filter(career => {
        // filter by location filter
        if (locationFilterId) {
          if (!career.acf.location) return false
          return career.acf.location.id === locationFilterId
        } else {
          return career
        }
      })
  }, [nameFilterString, departmentFilterId, locationFilterId, props.careers])

  return (
    <div className="careers-table-filter-section">
      <div
        id={props.anchor}
        className="careers-table-filter-section__anchor"
      ></div>
      <div className="page-wrap">
        <CareersFilterRow
          departments={props.departments}
          locations={props.locations}
          total={filterCareers.length}
          onDepartmentFilterChange={setDepartmentFilterId}
          onLocationFilterChange={setLocationFilterId}
          onNameFilterChange={setNameFilterString}
          nameValue={nameFilterString}
          language={props.language}
        />
        <CareersTable
          departmentFilterId={departmentFilterId}
          locationFilterId={locationFilterId}
          careers={filterCareers}
          language={props.language}
        />
      </div>
    </div>
  )
}

CareersTableFilterSection.propTypes = {
  anchor: PropTypes.string,
  careers: PropTypes.array,
  locations: PropTypes.array,
  departments: PropTypes.array,
  language: PropTypes.string,
}
CareersTableFilterSection.defaultProps = {
  careers: [],
  locations: [],
  departments: [],
  language: "es",
}

export default CareersTableFilterSection
