import React from "react"
import PropTypes from "prop-types"

import Table from "@components/common/Table"

import IconLinkButton from "@components/common/IconLinkButton"
import UniversalLink from "@components/common/UniversalLink"
import BodyText from "@components/common/BodyText"
import { ReactComponent as ChevronRight } from "@images/icons/chevron-right.svg"

import { returnPublishFinal } from "@utilities/dataUtils"
import language from "@language"

import "./style.scss"

const CareersTable = props => {
  const columns = React.useMemo(
    () => [
      {
        Header: language[props.language].title,
        accessor: "title",
        Cell: rowInfo => {
          return (
            <UniversalLink href={rowInfo.row.original.uri}>
              <BodyText className="text-link" color="primary" size="sm">
                {rowInfo.value}
              </BodyText>
            </UniversalLink>
          )
        },
      },
      {
        Header: language[props.language].location,
        accessor: "location",
        Cell: rowInfo => (
          <BodyText
            key={rowInfo.value}
            color="dark-gray"
            fontWeight="300"
            size="sm"
          >
            {rowInfo.value}
          </BodyText>
        ),
      },
      {
        Header: language[props.language].department,
        accessor: "department",
        Cell: rowInfo => (
          <BodyText
            key={rowInfo.value}
            color="dark-gray"
            fontWeight="300"
            size="sm"
          >
            {rowInfo.value}
          </BodyText>
        ),
      },
      // {
      //   Header: language[props.language].compensation,
      //   accessor: "compensation",
      //   Cell: rowInfo => (
      //     <BodyText
      //       key={rowInfo.value}
      //       color="dark-gray"
      //       fontWeight="300"
      //       size="sm"
      //     >
      //       {rowInfo.value}
      //     </BodyText>
      //   ),
      // },
      {
        Header: "",
        accessor: "moreInfo",
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [props.language]
  )

  const data = React.useMemo(() => {
    const constructCareersRowData = (langCode, career, index) => {
      return {
        key: `careers-row--${index}`,
        uri: career.uri,
        title: career.acf.title,
        department: (career.acf.employeeDepartment || {}).name,
        location: (returnPublishFinal(career.acf.location) || {}).title,
        // compensation: career.acf.compensation,
        moreInfo: (
          <IconLinkButton
            uri={career.uri}
            iconSide="right"
            text={language[langCode].more_info}
            textcolor="primary"
            icon={<ChevronRight />}
          />
        ),
      }
    }

    return props.careers.map((career, index) =>
      constructCareersRowData(props.language, career, index)
    )
  }, [props.language, props.careers])
  return <Table columns={columns} data={data} language={props.language} />
}

CareersTable.propTypes = {
  departmentFilterId: PropTypes.string,
  locationFilterId: PropTypes.string,
  careers: PropTypes.array,
  language: PropTypes.string,
}
CareersTable.defaultProps = {
  careers: [],
  language: "es",
}

export default CareersTable
