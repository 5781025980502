import React from "react"
import PropTypes from "prop-types"
import FilterSelectNavigator from "@components/common/FilterSelectNavigator"
import BodyText from "@components/common/BodyText"

import language from "@language"
import classnames from "classnames"
import * as c from "@utilities/constants"

import "./style.scss"
import FormInput from "../../../../components/common/FormInput"

const CareersFilterRow = props => {
  const locationOptions = props.locations.map(type => {
    return { value: type.id, label: type.title }
  })
  const departmentOptions = props.departments.map(type => {
    return { value: type.id, label: type.name }
  })

  return (
    <div className={classnames("careers-filter-row", props.className)}>
      <div className="careers-filter-row__row">
        <FormInput
          className="careers-filter-row__input"
          placeholder={language[props.language].search_by_name}
          onChange={e => props.onNameFilterChange(e.target.value)}
          value={props.nameValue}
        />
        <FilterSelectNavigator
          className="careers-filter-row__select"
          onChange={option => {
            props.onLocationFilterChange((option || {}).value)
          }}
          options={locationOptions}
          placeholder={language[props.language].location}
          language={props.language}
          queryParamKey={c.LOCATION_PARAM_KEY}
        />
        <FilterSelectNavigator
          className="careers-filter-row__select"
          onChange={option =>
            props.onDepartmentFilterChange((option || {}).value)
          }
          options={departmentOptions}
          placeholder={language[props.language].department}
          queryParamKey={c.DEPARTMENT_PARAM_KEY}
          language={props.language}
        />
        <BodyText
          className="careers-filter-row__message"
          color={props.resultStatus === 500 ? "error" : "dark-gray"}
          size="sm"
          text={props.resultMessage}
        />
      </div>

      <hr className="careers-filter-row__divider divider divider__light-gray" />
      <BodyText
        className="careers-filter-row__label"
        size="sm"
        color="dark-gray"
        text={language[props.language].sentences.showingXResults(props.total)}
      />
    </div>
  )
}

CareersFilterRow.propTypes = {
  className: PropTypes.string,
  departments: PropTypes.array,
  language: PropTypes.string,
  locations: PropTypes.array,
  total: PropTypes.number,
  onLocationFilterChange: PropTypes.func,
  onDepartmentFilterChange: PropTypes.func,
  onNameFilterChange: PropTypes.func,
  nameValue: PropTypes.string,
}
CareersFilterRow.defaultProps = {
  departments: [],
  language: "es",
  locations: [],
  total: 0,
  value: "",
}

export default CareersFilterRow
