import React from "react"
import { graphql } from "gatsby"
import gql from "graphql-tag"
import withPreview from "@components/withPreview"

import Layout from "@components/layout"
import SEO from "@components/seo"
import SimpleBreadcrumbHero from "@components/common/SimpleBreadcrumbHero"
import { preFilterMarkdown } from "@utilities/markdownUtils"
import Markdown from "markdown-to-jsx"

import BodyText from "@components/common/BodyText"
import PhotoCarousel from "@components/common/PhotoCarousel"
import CustomImage from "@components/common/CustomImage"
import BrandButton from "@components/common/BrandButton"
import HeadingText from "@components/common/HeadingText"
import NewsCard from "../../components/NewsCard"
import PageCarousel from "@components/PageCarousel"

import { filterPageForTemplate } from "@templates/utilities/templateUtils"

import * as c from "@utilities/constants"

import "./style.scss"
import CareersTableFilterSection from "./components/CareersTableFilterSection"

export const CareerIndex = props => {
  const page =
    !!props.preview && !!props.preview.page
      ? props.preview.page.preview.node
      : filterPageForTemplate(
        props.data.allWpPage.edges,
        props.pageContext.wordpressUri
      )

  const careers = props.data.allWpCareer.edges.map(e => e.node)
  const departments = props.data.allWpDepartment.edges.map(e => e.node)
  const locations = props.data.allWpLocation.edges.map(e => e.node)

  const recentNewsPosts = props.data.allWpNewsPost.edges.map(e => e.node)
  const newsItems =
    page.acfCareers.newsCarousel.mode === "manual"
      ? page.acfCareers.newsCarousel.news.filter(n => n.acf.publishFinal)
      : recentNewsPosts
  return (
    <Layout>
      <SEO title={page.acfPageMeta.metaTitle} />
      <div className="careers-page page-fade-in">
        <SimpleBreadcrumbHero
          overline={page.acfCareers.chero.overline}
          title={page.acfCareers.chero.title}
          pageTitle={page.acfPageMeta.metaTitle}
          image={page.acfCareers.chero.image}
          overlayType="full"
        />
        <div className="page-wrap careers-page__subcopy-section">
          <Markdown
            className="careers-page__subcopy"
            options={{
              forceInline: true,
              overrides: {
                p: {
                  component: BodyText,
                  props: {
                    color: "dark-gray",
                    size: "md2",
                  },
                },
              },
            }}
          >
            {preFilterMarkdown(page.acfCareers.subcopy)}
          </Markdown>
        </div>
        <div className="careers-page__carousel careers-page__section">
          <PhotoCarousel
            align="center"
            slides={page.acfCareers.imageCarousel.map((image, index) => (
              <CustomImage
                key={`careers-photo-carousel-item--${index}`}
                src={image.sourceUrl}
                alt={image.altText}
              />
            ))}
          />
        </div>
        <div className="careers-page__table careers-page__section">
          <CareersTableFilterSection
            careers={careers}
            departments={departments}
            locations={locations.filter(l => (l.acf.type || {}).slug === c.TYPE_HOSPITAL_SLUG)}
          />
        </div>
        <div className="careers-page__carousel careers-page__news-carousel">
          <div className="page-wrap content-spacing--lg">
            <PageCarousel
              childKeyIdentifier={"blog-card"}
              contentSlide={
                <div className="careers-page__news-copy">
                  {page.acfCareers.newsCarousel.title && (
                    <HeadingText
                      className="careers-page__news-title mb-40px"
                      text={page.acfCareers.newsCarousel.title}
                      size="xl"
                      element="h2"
                      color="primary"
                    />
                  )}
                  {page.acfCareers.newsCarousel.cta && (
                    <BrandButton
                      className="desktop-lg"
                      href={page.acfCareers.newsCarousel.cta.url}
                      text={page.acfCareers.newsCarousel.cta.title}
                    />
                  )}
                </div>
              }
              slides={newsItems.map((news, index) => {
                return (
                  <NewsCard
                    key={`careers-news-card--${index}`}
                    title={news.title}
                    description={news.acf.metaDescription}
                    image={news.acf.metaImage}
                    date={news.acf.displayDate}
                    uri={news.uri}
                    category={news.acf.category}
                    language={props.language}
                  />
                )
              })}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query CareersPageQuery {
    allWpPage(filter: { acfPageMeta: { pageTemplate: { eq: "careers" } } }) {
      edges {
        node {
          id
          uri
          slug
          acfPageMeta {
            metaImage {
              altText
              sourceUrl
            }
            metaDescription
            metaTitle
          }
          acfCareers {
            chero {
              overline
              title
              image {
                altText
                sourceUrl
              }
            }
            imageCarousel {
              altText
              sourceUrl
            }
            newsCarousel {
              title
              mode
              news {
                ... on WpNewsPost {
                  id
                  title
                  uri
                  acf {
                    publishFinal
                    displayDate
                    metaDescription
                    metaImage {
                      altText
                      sourceUrl
                    }
                    category {
                      ... on WpNewsCategory {
                        id
                        name
                        acfCategory {
                          color
                        }
                      }
                    }
                  }
                }
              }
              cta {
                title
                url
              }
            }
            subcopy
          }
        }
      }
    }
    allWpNewsPost(
      limit: 5
      sort: { fields: acf___displayDate, order: DESC }
      filter: { acf: { publishFinal: { eq: true } } }
    ) {
      edges {
        node {
          id
          title
          uri
          acf {
            displayDate
            metaDescription
            metaImage {
              altText
              sourceUrl
            }
            category {
              ... on WpNewsCategory {
                id
                name
                acfCategory {
                  color
                }
              }
            }
          }
        }
      }
    }
    allWpLocation {
      edges {
        node {
          id
          title
          acf {
            type {
              slug
            }
          }
        }
      }
    }
    allWpDepartment {
      edges {
        node {
          id
          name
        }
      }
    }
    allWpCareer(filter: { acf: { publishFinal: { eq: true } } }) {
      edges {
        node {
          title
          uri
          acf {
            applicationInformation
            applicationUrl
            description
            location {
              ... on WpLocation {
                id
                uri
                title
                acf {
                  publishFinal
                }
              }
            }
            postedDate
            qualifications
            title
          }
        }
      }
    }
  }
`

const PREVIEW_QUERY = gql`
  query PreviewDirectoryQuery($id: ID!) {
    page(id: $id, idType: DATABASE_ID) {
      preview {
        node {
          id
          uri
          slug
          acfPageMeta {
            metaImage {
              altText
              sourceUrl
            }
            metaDescription
            metaTitle
          }
          acfCareers {
            chero {
              overline
              title
            }
            imageCarousel {
              altText
              sourceUrl
            }
            newsCarousel {
              title
              mode
              news {
                ... on NewsPost {
                  id
                  title
                  uri
                  acf {
                    publishFinal
                    displayDate
                    metaDescription
                    metaImage {
                      altText
                      sourceUrl
                    }
                    category {
                      ... on NewsCategory {
                        id
                        name
                        acfCategory {
                          color
                        }
                      }
                    }
                  }
                }
              }
              cta {
                title
                url
              }
            }
            subcopy
          }
        }
      }
    }
  }
`

export default withPreview({ preview: PREVIEW_QUERY })(CareerIndex)
